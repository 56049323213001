<template>
  <f7-app :params="f7params" color-theme="orange">
      <f7-view url="/" :main="true" class="safe-areas" :master-detail-breakpoint="800"></f7-view>

      <!-- <f7-view url="/serviceterms/"></f7-view>
      <f7-view url="/privacypolicy/"></f7-view>
      <f7-view url="/projects_open/"></f7-view> -->

  </f7-app>
</template>
<script>
import { Device }  from 'framework7/framework7-lite.esm.bundle.js'
import routes from '~/js/router/routes.js'

export default {
  data() {
    return {
      // Framework7 Parameters
      f7params: {
        id: 'cloud.newways.ios', // App bundle ID
        name: 'newProject', // App name
        theme: 'auto', // Automatic theme detection
        version: 'v1.0.2',

        // App routes
        routes: routes,

        view: {
          pushState: true,
          // pushStateRoot: (function() {
          //   return location.pathname;
          // })(),
          pushStateSeparator: '#!'
        },

        // Register service worker
        serviceWorker: Device.cordova ? {} : {
          path: '/service-worker.js',
        },
        // Input settings
        input: {
          scrollIntoViewOnFocus: Device.cordova && !Device.electron,
          scrollIntoViewCentered: Device.cordova && !Device.electron,
        },
        // Cordova Statusbar settings
        statusbar: {
          iosOverlaysWebView: true,
          androidOverlaysWebView: false,
          // iosTextColor: 'black',
          // androidTextColor: 'black',
        },
        popup: {
          closeOnEscape: true,
        },
        sheet: {
          closeOnEscape: true,
        },
        popover: {
          closeOnEscape: true,
        },
        actions: {
          closeOnEscape: true,
        },
      },
    }
  },
  metaInfo () {
    return {
      title: 'newProject'
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
  }
}
</script>
