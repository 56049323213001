<template>
  <f7-page class="grid-demo"
      infinite
      :infinite-distance="50"
      :infinite-preloader="showPreloader"
      @infinite="loadMore">
    <f7-navbar>
      <f7-nav-left>
        <f7-link popover-open=".popover-menu-engineer">
          <f7-icon ios="f7:bars" aurora="f7:bars" md="material:menu"></f7-icon>
        </f7-link>
      </f7-nav-left>
      <f7-nav-title>{{ $t('engineer') }}</f7-nav-title>
      <f7-nav-right>
        <f7-link class="searchbar-enable" data-searchbar=".searchbar-engineer-open" icon-ios="f7:search" icon-md="material:search" icon-aurora="f7:search"></f7-link>
      </f7-nav-right>
      <f7-searchbar
        class="searchbar-engineer-open"
        expandable
        search-container=".search-list-engineer-open"
        search-in=".item-header, .item-title, .item-subtitle, .item-text, .item-footer"
        :disable-button="!$theme.aurora"
        :disable-button-text="$t('cancel')"
        placeholder="性別/年数/日本語/技術/業務/工程/職位/所属会社/"
        :clear-button="true"
      ></f7-searchbar>
      <f7-subnavbar>
        <f7-segmented raised>
          <f7-button outline small v-on:click.stop.prevent="search">再検索</f7-button>
          <MonthSwitch/>
          <SortSwitch/>
        </f7-segmented>
      </f7-subnavbar>
    </f7-navbar>
    <f7-list class="searchbar-not-found">
      <f7-list-item :title="$t('Nothing found')"></f7-list-item>
    </f7-list>
    <!-- top left menu  -->
    <f7-popover ref="popoverMenuEngineer" class="popover-menu-engineer">
      <!-- <f7-list>
        <f7-list-item v-on:click.stop.prevent="QueryCondition()" :title="$t('query condition setting')"></f7-list-item>
      </f7-list> -->
    </f7-popover>

    <f7-list media-list class="search-list-engineer-open searchbar-found">
      <f7-list-item v-for="(item,index) in items"
        swipeout
        :link="'/detail_engineer_open/'+item.id+'/'"
        :header="item.name_sex_age+', 登録日:'+item.updated_at"
        :title="item.foreign_language+','+item.experience_years_str"
        :subtitle="item.skill"
        :text="item.business_experience+','+item.participation_process+','+item.manage_experience"
        :footer="'管理No:'+item.id"
        :after="item.available_day+', '+item.desired_price"
        :key="index">
      </f7-list-item>
    </f7-list>

    <!-- <f7-card v-if="company.cert_flag==0">
      <f7-card-content>
        <p>会社印鑑証明書は未確認のため、技術情報は20件まで表示します</p>
      </f7-card-content>
    </f7-card> -->

  </f7-page>
</template>

<script>
import axios from 'axios'
import swal from "sweetalert"
import { mapGetters } from 'vuex'
import MonthSwitch from '~/js/components/MonthSwitch_engineer_open'
import SortSwitch from '~/js/components/SortSwitch_engineer_open';

export default {
  components: {
    MonthSwitch,
    SortSwitch,
  },
  metaInfo() {
    return { title: this.$t('engineer') }
  },
  data() {
    return {
      allowInfinite: true,
      showPreloader: true,
      curr_item: {
        item: null,
        index: null
      },
      items: [],
      pagination: {
        per_page: 20,    // 每页件数 固定
        pager_count: 3,  // 显示页数 固定
        current_page: 1, // 当前页 変わる
        total: 0,        // 总条目数
      },
      current_month: {
        value: '0',
        label: '',
      },
      sort_current: {
        value: '1',
        label: '登録時間 ↑',
      },
    }
  },
  created() {
  },
  mounted() {
    const engineer_open_sort = this.$store.state.sortSwitch.engineer_open_sort;
    if (engineer_open_sort) {
      this.sort_current = engineer_open_sort
    }
    const current_month_stored = this.$store.state.monthSwitch.current_month_engineer_open;
    if (current_month_stored) {
      this.current_month = current_month_stored
    }
    this.init()
    this.loadMore()
  },
  computed: mapGetters({
    current_month_stored: 'monthSwitch/current_month_engineer_open',
    engineer_open_sort: 'sortSwitch/engineer_open_sort',
    added_engineer_id: 'pushed/added_engineer_id',
    updated_company: 'pushed/updated_company',
  }),
  watch: {
    // 如果 `current_month` 发生改变，这个函数就会运行
    current_month_stored: function (newCurrentMonth, oldCurrentMonth) {
      this.current_month = newCurrentMonth
      this.init()
      this.loadMore()
    },
    engineer_open_sort: function (newVal, oldVal) {
      this.sort_current = newVal
      this.init()
      this.loadMore()
    },
  },
  methods: {
    init() {
      this.pagination.current_page = 0
      this.pagination.total = 0
      this.items = []
      this.curr_item.item = null
      this.curr_item.index = null
      this.allowInfinite = true
      this.showPreloader = true
    },
    loadMore() {
      const self = this;
      if (!self.allowInfinite) return;
      self.allowInfinite = false;
      setTimeout(() => {
        //最大2000件まで
        if (self.items != null) {
          if (self.items.length >= 2000) {
            self.showPreloader = false;
            self.allowInfinite = false;
            return;
          }
        }
        this.pagination.current_page = this.pagination.current_page + 1
        this.getEngineerList()
        self.allowInfinite = true;
      }, 1000);
    },
    search() {
      this.init()
      this.loadMore()
    },
    getEngineerList() {
      const options = {
        params: {
          paginate: this.pagination.per_page,
          page: this.pagination.current_page,
          current_month: this.current_month.value,
          current_sort: this.sort_current.value
        }
      };
      axios.get('/api/admin/engineer/get_engineer_list_open_share', options)
      .then(response => {
        let data = response.data.list.data
        this.pagination.total = response.data.list.total
        this.pagination.current_page = response.data.list.current_page
        this.items.push(...data)
        if (this.items.length >= this.pagination.total) {
          this.showPreloader = false
          this.allowInfinite = false
        }
        // if (this.company.cert_flag == 0) {
        //   this.allowInfinite = false
        //   this.showPreloader = false
        // }
      })
      .catch(function (error) {
        swal({
          icon: "error",
          text: error,
        })
      })
    },
    more(index, item) {
      // const self = this;
      // self.actions.open();
      this.curr_item.item = item
      this.curr_item.index = index
    },
    QueryCondition() {
      this.$refs.popoverMenuEngineer.close()
      this.$f7router.navigate('/engineer_query_condition/')
    },
  }
}
</script>
<style>
</style>
