window._ = require('lodash');
window.Popper = require('popper.js').default;

// Import Vue
import Vue from 'vue';
import store from '~/js/store';
import i18n from '~/js/plugins/i18n';
import '~/js/plugins';
import '~/js/components';

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

Vue.config.productionTip = false;

// Import Framework7
import Framework7 from 'framework7/framework7-lite.esm.bundle.js';
// import Framework7 from 'framework7/framework7.esm.bundle.js';

// Import Framework7-Vue Plugin
import Framework7Vue from 'framework7-vue/framework7-vue.esm.bundle.js';

// Import Framework7 Styles
import 'framework7/css/framework7.bundle.css';
import 'framework7-icons/css/framework7-icons.css'; //牛追加

// Import Icons and App Custom Styles
import '../css/icons.css';
import '../css/app.css';

// Import App Component
import App from '@/components/app.vue';
// import App from '@/js/components/App';

// Init Framework7-Vue Plugin
Framework7.use(Framework7Vue);

// Init App
new Vue({
  el: '#app',
  render: (h) => h(App),

  i18n,
  store,

  // render: c => c(App),
  // Register App Component
  components: {
    app: App
  },
});
