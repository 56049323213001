<template>
  <f7-page >
    <f7-navbar back-link-url="/engineers_open/" back-link="技術者一覧" title=""></f7-navbar>
    <div class="job">
      <div class="engineer-banner">
        <h1 class="name">
          <span class="favorite" v-on:click.stop.prevent="app_open">
            <f7-icon
              ios="f7:app" aurora="f7:app" md="material:crop_din">
            </f7-icon>
          </span>
          <p class="job-title">{{form.name_sex_age}}</p>
        </h1>
        <p style="font-size:12px;padding-bottom: 5px;">
          {{form.available_day}}
          <span class="vline">|</span>
          {{form.experience_years}} 年
          <span class="vline">|</span>
          {{form.desired_price}} 万
          <span class="vline">|</span>
          {{form.nearest_station}} 駅
        </p>
        <div v-if="!form.skill==''" class="job-tags">
          <div style="skill">
            <span class="skill">{{form.skill}}</span>
          </div>
        </div>
        <div v-if="!form.foreign_language==''" class="job-tags">
          <span class="skill">{{form.foreign_language}}</span>
        </div>
        <div class="time">{{ $t('updated at') }}：{{form.updated_at}}</div>
      </div>

      <div class="job-detail">
        <div>
          <div style="display: inline-block">
            <h3>{{ $t('proposal description') }}</h3>
          </div>
          <div style="display: inline-block;vertical-align:middle">
            <span v-on:click="write2clipboard">
              <f7-icon class="size-19"
                ios="f7:square_on_square" aurora="f7:square_on_square" md="material:content_copy">
              </f7-icon>
            </span>
          </div>
        </div>
        <div class="text">{{this.proposal_desc}}</div>
      </div>

      <div class="job-author">
        <div class="name">{{form.company_name}}</div>
        <div style="display: inline-block;">
          <p class="gray">営業wechat：{{form.wechat_no}}</p>
        </div>
        <div style="display: inline-block;vertical-align:middle">
          <!-- <i class="f7-icons size-16" @click="write2clipboard_wechat">doc_on_clipboard</i> -->
          <span v-on:click="write2clipboard_wechat">
            <f7-icon class="size-16"
              ios="f7:square_on_square" aurora="f7:square_on_square" md="material:content_copy">
            </f7-icon>
          </span>
        </div>
      </div>
    </div>
    <f7-list>
      <!-- APPに移動-->
      <f7-list-button
          title="newProject APPを開く"
          v-on:click.stop.prevent="app_open">
      </f7-list-button>
    </f7-list>
  </f7-page>
</template>

<script>
import { Device }  from 'framework7/framework7-lite.esm.bundle.js'
import axios from 'axios'
import swal from "sweetalert"
import Form from 'vform'
import moment from 'moment'
import * as FileSaver from 'file-saver'
// import from 'cordova-plugin-file'

export default {
  name: 'DetailEngineer',
  data() {
    return {
      engineer_id: null,
      form: new Form({
          id: '',
          name: '',
          gender: '',
          birthday: '',
          name_sex_age: '',
          age: '25',
          foreign_language: '',
          skill: '',
          participation_process: '',
          experience_years: '',
          business_experience: '',
          manage_experience: '',
          participation_process: '',
          nearest_station: '',
          available_day: '',
          availability: '',
          purchase_price: '',
          desired_price: '',
          customer_company_name: '',
          customer_name: '',
          company_name: '',
          mobile_telno: '',
          wechat_no: '',
          comment1: '',
          comment2: '',
          comment3: '',
          comment4: '',
          updated_at: '',
          imagePreviewConfig: '',
          resume_ServerFileName: '',
          resume_file_name: '',
      }),
      proposal_desc: '',
      platformId: 'ios',
    };
  },
  created() {
    this.engineer_id = this.$f7route.params.id
    this.get_engineer()
  },
  mounted() {
    if (!Device.cordova) {
      this.platformId = 'ios'  //pcの場合iosとする
    } else {
      this.platformId = cordova.platformId //ios,android,osx
    }
  },
  methods: {
    app_open() {
      //newProject APPを開く
      window.location.href = 'https://newproject.newways.cloud/'
    },
    write2clipboard() {
      if (!Device.cordova) {
          navigator.clipboard.writeText(this.proposal_desc).catch((e) => {
            console.error(e)
          })
      } else {
          cordova.plugins.clipboard.copy(this.proposal_desc);
      }
      var toastSuccess = this.$f7.toast.create({
        text: this.$t('proposal description')+'をコピーしました',
        position: 'center',
        closeTimeout: 1000,
      })
      toastSuccess.open()
    },
    write2clipboard_wechat() {
      if (!Device.cordova) {
          navigator.clipboard.writeText(this.form.wechat_no).catch((e) => {
            console.error(e)
          })
      } else {
          cordova.plugins.clipboard.copy(this.form.wechat_no);
      }
      var toastSuccess = this.$f7.toast.create({
        text: 'WechatIdをコピーしました',
        position: 'center',
        closeTimeout: 1000,
      })
      toastSuccess.open()
    },
    set_desc_msg() {
      this.proposal_desc = ''
      if (this.form.name_sex_age) {
        this.proposal_desc = this.proposal_desc + '【氏　　名】' + this.form.name_sex_age + "\n"
      }
      if (this.form.skill) {
        this.proposal_desc = this.proposal_desc + '【スキル】' + this.form.skill + "\n"
      }
      if (this.form.foreign_language) {
        this.proposal_desc = this.proposal_desc + '【語学能力】' + this.form.foreign_language + "\n"
      }
      if (this.form.experience_years) {
        this.proposal_desc = this.proposal_desc + '【経験年数】' + this.form.experience_years + "\n"
      }
      if (this.form.business_experience) {
        this.proposal_desc = this.proposal_desc + '【業務経験】' + this.form.business_experience + "\n"
      }
      if (this.form.participation_process) {
        this.proposal_desc = this.proposal_desc + '【参画工程】' + this.form.participation_process + "\n"
      }
      if (this.form.manage_experience) {
        this.proposal_desc = this.proposal_desc + '【管理経験】' + this.form.manage_experience + "\n"
      }
      if (this.form.nearest_station) {
        this.proposal_desc = this.proposal_desc + '【最寄り駅】' + this.form.nearest_station + "\n"
      }
      if (this.form.availability) {
        this.proposal_desc = this.proposal_desc + '【稼動日】' + this.form.availability + "\n"
      }
      if (this.form.desired_price) {
        this.proposal_desc = this.proposal_desc + '【希望単金】' + this.form.desired_price + "\n"
      }
      if (this.form.comment1) {
        this.proposal_desc = this.proposal_desc + this.form.comment1 + "\n"
      }
      if (this.form.comment2) {
        this.proposal_desc = this.proposal_desc + this.form.comment2 + "\n"
      }
      if (this.form.comment3) {
        this.proposal_desc = this.proposal_desc + this.form.comment3 + "\n"
      }
    },
    get_engineer() {
      const options = {
        params: {
          id: this.engineer_id,
        }
      };
      axios.get('/api/admin/engineer/get_engineer_share', options)
      .then(response => {
        let item = response.data.engineer
        this.form.keys().forEach(key => {
          this.form[key] = item[key]
        })
        this.set_desc_msg()
      })
      .catch(function (error) {
        swal({
          icon: "error",
          text: error,
        })
      })
    },
  }
}
</script>
<style>
</style>
