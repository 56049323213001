import NotFoundPage from '~/pages/404.vue';

import Home from '~/js/pages/home.vue';

import Manage from '~/js/pages/manage/index.vue';
import AddEngineer from '~/js/pages/manage/AddEngineer.vue';
import UploadEngineerResume from '~/js/pages/manage/UploadEngineerResume.vue';
import DetailEngineer from '~/js/pages/manage/DetailEngineer.vue';

import AddProject from '~/js/pages/manage/AddProject.vue';
import DetailProject from '~/js/pages/manage/DetailProject.vue';
import AddItem from '~/js/pages/manage/add_item.vue';

import EngineersOpen from '~/js/pages/engineers_open/index.vue';
import DetailEngineerOpen from '~/js/pages/engineers_open/DetailEngineer.vue';

import ProjectsOpen from '~/js/pages/projects_open/index.vue';
import DetailProjectOpen from '~/js/pages/projects_open/DetailProject.vue';

import ServiceTerms from '~/js/pages/manual/ServiceTerms.vue';
import PrivacyPolicy from '~/js/pages/manual/PrivacyPolicy.vue';

export default [
  { path: '/', component: Home, },

  { path: '/serviceterms/', component: ServiceTerms, name: 'serviceterms' },
  { path: '/privacypolicy/', component: PrivacyPolicy, name: 'privacypolicy' },

  { path: '/projects_open/', component: ProjectsOpen, name: 'projects_open' },
  { path: '/detail_project_open/:id/', component: DetailProjectOpen, name: 'detail_project_open' },

  { path: '/engineers_open/', component: EngineersOpen, name: 'engineers_open' },
  { path: '/detail_engineer_open/:id/', component: DetailEngineerOpen, name: 'detail_engineer_open' },

  // { path: '/manage/', component: Manage, name: 'manage' },
  { path: '/add_engineer/:company_id/', component: AddEngineer, name: 'add_engineer' },
  { path: '/add_project/:company_id/', component: AddProject, name: 'add_project' },

  { path: '/upload_engineer_resume/:options', component: UploadEngineerResume, name: 'upload_engineer_resume' },

  { path: '(.*)', component: NotFoundPage, }

]
