<template>
  <f7-page>
    <f7-navbar back-link-url="/projects_open/" back-link="案件一覧" title=""></f7-navbar>
    <div class="job">
      <div class="job-banner">
        <h1 class="name">
          <span class="favorite" v-on:click.stop.prevent="app_open">
            <f7-icon
              ios="f7:app" aurora="f7:app" md="material:crop_din">
            </f7-icon>
          </span>
          <p class="job-title">{{form.project_name}}</p>
        </h1>
        <p style="font-size:12px;padding-bottom: 5px;">
          {{form.available_day}}
          <!-- <span class="vline">|</span>
          {{form.experience_years}} 年
          <span class="vline">|</span>
          {{form.desired_price}} 万 -->
          <span class="vline">|</span>
          {{form.nearest_station}} 駅
        </p>
        <div v-if="!form.skill==''" class="job-tags">
          <span class="skill">{{form.skill}}</span>
        </div>
        <div v-if="!form.foreign_language==''" class="job-tags">
          <span class="skill">{{form.foreign_language}}</span>
        </div>
        <div class="time">{{ $t('updated at') }}：{{form.updated_at}}</div>
      </div>
      <div class="job-detail">
        <h3>{{ $t('req description') }}</h3>
        <div class="text">{{form.req_desc}}</div>
      </div>
      <div class="job-author">
        <div class="name">{{form.company_name}}</div>
        <div style="display: inline-block;">
          <p class="gray">営業wechat:{{form.wechat_no}}</p>
        </div>
        <div v-if="!form.wechat_no==''" style="display: inline-block;vertical-align:middle">
          <!-- <i class="f7-icons size-16" @click="write2clipboard_wechat">doc_on_clipboard</i> -->
          <span v-on:click="write2clipboard_wechat">
            <f7-icon class="size-16"
              ios="f7:square_on_square" aurora="f7:square_on_square" md="material:content_copy">
            </f7-icon>
          </span>
        </div>
      </div>
    </div>
    <f7-list>
      <!-- APPに移動-->
      <f7-list-button
          title="newProject APPを開く"
          v-on:click.stop.prevent="app_open">
      </f7-list-button>
    </f7-list>
  </f7-page>
</template>

<script>
import { Device }  from 'framework7/framework7-lite.esm.bundle.js';
import axios from 'axios'
import swal from "sweetalert"
import moment from 'moment';

export default {
  name: 'DetailProject',
  data() {
    return {
      project_id: null,
      form: {},
      platformId: 'ios',
    };
  },
  created() {
    if (!Device.cordova) {
      this.platformId = 'ios'  //pcの場合iosとする
    } else {
      this.platformId = cordova.platformId //ios,android,osx
    }
  },
  mounted() {
    this.project_id = this.$f7route.params.id
    this.get_project()
  },
  methods: {
    app_open() {
      //newProject APPを開く
      window.location.href = 'https://newproject.newways.cloud/'
    },
    write2clipboard_wechat() {
      if (!Device.cordova) {
        navigator.clipboard.writeText(this.form.wechat_no).catch((e) => {
          console.error(e)
        })
      } else {
        cordova.plugins.clipboard.copy(this.form.wechat_no);
      }
      var toastSuccess = this.$f7.toast.create({
        text: 'WechatIdをコピーしました',
        position: 'center',
        closeTimeout: 1000,
      })
      toastSuccess.open()
    },
    get_project() {
      const options = {
        params: {
          id: this.project_id,
        }
      };
      axios.get('/api/admin/project_open/get_project_share', options)
      .then(response => {
        let item = response.data.project
        this.form = item
        // this.form.keys().forEach(key => {
        //   this.form[key] = item[key]
        // })
      })
      .catch(error => {
        if(error.response.status == 422){
          if (error.response.data.errors) {
          } else {
            if (error.response.data.message) {
              swal({
                icon: "warning",
                text: error.response.data.message,
              })
            }
          }
        } else {
          swal({
            icon: "error",
            text: error,
          });
        }
      })
    },
  }
}
</script>
<style>
</style>
